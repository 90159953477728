import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { Modal } from 'react-responsive-modal';

export default function Verify(props) {

    const [userData, setUserData] = useState(null)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDefault, setIsDefault] = useState(false)

    useEffect(async () => {
        fetchUpdate()
    }, [setUserData, setIsAdmin])



    const fetchUpdate = () => {
        try {
            const parts = window.location.href.split("/");
            const lastPart = parts[parts.length - 1];

            if (lastPart === "scan") {
                return setIsDefault(true)
            }
            try {
                const userData = JSON.parse(localStorage.getItem('userData'))
                setIsAdmin(userData.admin ? true : false)
            } catch (error) { }


            // CHECK IF ALREADY REGISTERED
            axios.get(`https://api.grabnext2024.com/metadata/verify/` + lastPart).then((dt) => {
                setUserData(dt.data)
            }).catch((err) => {
                // NotificationManager.error("You are not registered to the PDS Convention. Register for the event first before reserving workshops.", '', 5000);
            })
        } catch (error) { }
    }

    const registerToEvent = (workshop) => {
        setIsLoading(true)
        console.log(userData?.user.id)
        // return
        // const userData = JSON.parse(localStorage.getItem('userData'))
        const accessToken = localStorage.getItem('accessToken')

        // CHECK IF ALREADY REGISTERED
        axios.post(`https://api.grabnext2024.com/metadata/registerevent/` + userData?.user.id,
            {
                "day": "1",
                "category": "day1mainevent",
                "workshop": workshop
            },
            {
                headers: {
                    'authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            },
        ).then((dt) => {
            fetchUpdate()
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
        })
    }

    return (
        <div id={'bgmainpagelogin'} className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
            <Modal
                closeOnOverlayClick={false}
                open={isLoading}
                showCloseIcon={false}
                center>
                <div style={{ height: '150px', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <h6 style={{ color: 'black', margin: 10 }}>LOADING...</h6>
                    <Dots color={'black'} size={50} />
                </div>
            </Modal>

            <div id="wrapper" className="animsition">
                <div id="page" className="clearfix">
                    <div id="main-content" className="site-main clearfix">
                        <div id="content-wrap">
                            <div className="page-content">

                                <div className="step-inner-content" style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: '2rem'
                                }}>
                                    {isDefault ?
                                        <div style={{
                                            borderRadius: '2rem',
                                            background: "rgb(0,173,78)",
                                            background: "linear-gradient(180deg, rgba(0,173,78,1) 0%, rgba(1,87,58,1) 100%)",
                                            maxWidth: 770,
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            padding: '1rem'
                                        }}>
                                            <div>
                                                <div className='row justify-content-md-center'>
                                                    <div className='col-12 mt-5'>
                                                        <img style={{ width: '100%', maxWidth: '100%' }} src={"https://grabnext2024.com/wp-content/uploads/2024/02/grab-heading-logo.svg"} alt="board" />
                                                    </div>
                                                </div>


                                                <div className='justify-content-md-center mt-5'>
                                                    <h3 style={{ textAlign: 'center', color: 'white',lineHeight: '25px' }}>Scan QR to get started</h3>
                                                </div>

                                                <div className='row justify-content-md-center'>
                                                    <div className='col-3'></div>
                                                    <div className='col-6 mt-5'>
                                                        <img style={{ width: '100%', maxWidth: '100%' }} src={"https://www.pikpng.com/pngl/b/192-1922041_qr-code-icon-white-clipart.png"} alt="board" />
                                                    </div>
                                                    <div className='col-3'></div>
                                                </div>

                                                <div className='justify-content-md-center mt-5'>
                                                    {/* <p style={{ textAlign: 'center', color: 'white' }}>Scan</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {userData ?
                                                <div style={{
                                                    borderRadius: '2rem',
                                                    background: "rgb(0,173,78)",
                                                    background: "linear-gradient(180deg, rgba(0,173,78,1) 0%, rgba(1,87,58,1) 100%)",
                                                    maxWidth: 770,
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                    padding: '1rem'
                                                }}>

                                                    <div>
                                                        <div className='row justify-content-md-center'>
                                                            {/* <div className='col-4'></div> */}
                                                            <div className='col-12 mt-5'>
                                                                <img style={{ width: '100%', maxWidth: '100%' }} src={"https://grabnext2024.com/wp-content/uploads/2024/02/grab-heading-logo.svg"} alt="board" />
                                                            </div>
                                                            {/* <div className='col-4'></div> */}
                                                        </div>


                                                        {/* <div className='justify-content-md-center mt-5'>
                                                            <h3 style={{ textAlign: 'center', color: 'white' }}>User Verification</h3>
                                                            <p style={{ textAlign: 'center', color: 'white' }}>ID Scan Result</p>

                                                        </div> */}

                                                    </div>

                                                    <div>

                                                        <div className='row justify-content-md-center'>
                                                            <div className='col-4'></div>
                                                            <div className='col-4 mt-5'>
                                                                <img style={{ width: '100%', maxWidth: '100%' }} src={"../../../assets/img/sign-check-icon.png"} alt="board" />
                                                            </div>
                                                            <div className='col-4'></div>
                                                        </div>
                                                        <div className='justify-content-md-center mt-5 mb-5'>
                                                            <h3 style={{ textAlign: 'center', color: 'white', lineHeight: '25px' }}>Verified Attendee</h3>
                                                            {/* <p style={{ textAlign: 'center' }}>Remember to present your ID to the admins<br></br> before entering the venue</p> */}

                                                        </div>
                                                        {userData &&
                                                            <div style={{ marginLeft: '2rem', marginRight: '2rem', marginBottom: '2rem' }} className='justify-content-md-center mt-5'>
                                                                <p style={{ marginTop: 5, textAlign: 'center', lineHeight: '25px' }}> Name: {userData?.user?.firstName} {userData?.user?.lastName}</p>
                                                                <p style={{ marginTop: 5, textAlign: 'center', lineHeight: '25px' }}> Email: {userData?.user?.email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")}</p>

                                                                {/* {userData?.user?.pdsNumber !== "" &&
                                                    <p style={{ marginTop: 5, textAlign: 'left' }}> PDS Number: {userData?.user?.pdsNumber}</p>
                                                } */}
                                                                {/* <p style={{ marginTop: 5, textAlign: 'left' }}> Membership Type: {userData?.user?.categoryId}</p> */}

                                                                {/* <p style={{ marginTop: 5, textAlign: 'left', color: userData?.user?.businessMeeting ? 'green' : 'red' }}> {userData?.user?.businessMeeting ? '✔ Attending business meeting' : '✖ Not attending business meeting'}</p>


                                                <p style={{ marginTop: 5, textAlign: 'left', color: userData?.user?.fellowship ? 'green' : 'red' }}> {userData?.user?.fellowship ? '✔ Attending fellowship night' : '✖ Not attending fellowship night'}</p> */}

                                                                {/* {isAdmin &&
                                                    <> */}
                                                                {/* {userData?.user?.accompany_first_name &&
                                                            <div className='col-12 card'
                                                                style={{
                                                                    marginTop: 10,
                                                                    padding: 20,
                                                                }}>

                                                                <div>
                                                                    <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>Accompanied by:</p>
                                                                    <p style={{ marginRight: 10, marginBottom: 10 }}>{userData?.user?.accompany_first_name} {userData?.user?.accompany_last_name}</p>
                                                                    <p style={{ marginRight: 10, marginBottom: 10 }}>{userData?.user?.accompany_email}</p>
                                                                </div>
                                                            </div>
                                                        } */}
                                                                {/* </>
                                                } */}
                                                            </div>
                                                        }

                                                        {isAdmin &&
                                                            <div className='card-deck justify-content-md-center' style={{ marginLeft: '2rem', marginRight: '2rem', marginBottom: '2rem' }}>

                                                                {userData?.main?.sort((workshopA, workshopB) => {
                                                                    if (workshopA.day < workshopB.day) {
                                                                        return -1;
                                                                    }
                                                                    if (workshopA.day > workshopB.day) {
                                                                        return 1;
                                                                    }
                                                                    return 0;
                                                                }).map((session, index) => {
                                                                    return <div  key={index}
                                                                        style={{
                                                                            // marginTop: 10,
                                                                            // padding: 20,
                                                                            pointerEvents: session.isRegistered ? 'none' : 'all',
                                                                            opacity: session.isRegistered ? 0.5 : 1,
                                                                            backgroundColor: "transparent"
                                                                        }}>

                                                                        <div>
                                                                            {/* <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>{session.title}</p> */}
                                                                            {/* <p style={{ marginRight: 10 }}>{session.assignment.toUpperCase()}</p> */}
                                                                            {/* <p style={{ marginRight: 10 }}>{session.startTime} - {session.endTime}</p> */}
                                                                        </div>

                                                                        {isAdmin ?
                                                                            <>
                                                                                <button
                                                                                    style={{
                                                                                        backgroundColor: session.isRegistered && 'lightgray'
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        // props.handleNext()
                                                                                        registerToEvent(session)
                                                                                    }}
                                                                                    className="themesflat-button bg-accent"
                                                                                >
                                                                                    {session.isRegistered ?
                                                                                        "Confirmed at " + new Date(session.isRegistered).toUTCString() : "Confirm Attendance"
                                                                                    }
                                                                                </button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {session.isRegistered &&
                                                                                    "Confirmed at " + new Date(session.isRegistered).toUTCString()
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                })}
                                                            </div>
                                                        }

                                                        {/* <div className="session-group" style={{ marginTop: 40 }}>
                                            <div className='card-deck' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                                                <h3>Main events</h3>
                                            </div>
                                            
                                        </div>



                                        {userData?.workshop &&
                                            <div className="session-group" style={{ marginTop: 40 }}>
                                                {userData.workshop.length > 0 &&
                                                    <div className='card-deck' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                                                        <h3>Registered FGD and Symposium Topics</h3>
                                                    </div>
                                                }
                                                <div className='card-deck justify-content-md-center' style={{ marginLeft: '2rem', marginRight: '2rem' }}>

                                                    {userData?.workshop?.sort((workshopA, workshopB) => {
                                                        if (workshopA.assignment < workshopB.assignment) {
                                                            return -1;
                                                        }
                                                        if (workshopA.assignment > workshopB.assignment) {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    }).map((session, index) => {
                                                        return <div className='col-12 card' key={index}
                                                            style={{
                                                                marginTop: 10,
                                                                padding: 20,
                                                                pointerEvents: session.isRegistered ? 'none' : 'all',
                                                                opacity: session.isRegistered ? 0.5 : 1,
                                                            }}>

                                                            <div>
                                                                <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>{session.title}</p>
                                                                <p style={{ marginRight: 10 }}>{session.assignment.toUpperCase()}</p>
                                                                <p style={{ marginRight: 10 }}>{session.startTime} - {session.endTime}</p>
                                                                <p style={{ marginRight: 10 }}>{session.type}</p>
                                                                <p style={{ marginRight: 10 }}>{session.price}</p>

                                                            </div>
                                                            {isAdmin ?
                                                                <>
                                                                    <button
                                                                        style={{
                                                                            backgroundColor: session.isRegistered && 'lightgray'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            // props.handleNext()
                                                                            registerToEvent(session)
                                                                        }}
                                                                        className="themesflat-button bg-accent"
                                                                    >
                                                                        {session.isRegistered ?
                                                                            "Confirmed at " + new Date(session.isRegistered).toUTCString() : "Confirm Attendance"
                                                                        }
                                                                    </button>
                                                                </>
                                                                :
                                                                <>
                                                                    {session.isRegistered &&
                                                                        "Confirmed at " + new Date(session.isRegistered).toUTCString()
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        }




                                        {userData?.precon &&
                                            <div className="session-group" style={{ marginTop: 40 }}>
                                                {userData.precon.length > 0 &&
                                                    <div className='card-deck' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                                                        <h3>Registered Pre-Convention Topics</h3>
                                                    </div>
                                                }
                                                <div className='card-deck justify-content-md-center' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                                                    {userData.precon.map((session, index) => {
                                                        return <div className='col-12 card' key={index}
                                                            style={{
                                                                marginTop: 10,
                                                                padding: 20,
                                                                pointerEvents: session.isRegistered ? 'none' : 'all',
                                                                opacity: session.isRegistered ? 0.5 : 1,
                                                            }}>

                                                            <div>
                                                                <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>{session.title}</p>
                                                                <p style={{ marginRight: 10 }}>{session.assignment} Room</p>
                                                                <p style={{ marginRight: 10 }}>{session.startTime} - {session.endTime}</p>
                                                                <p style={{ marginRight: 10 }}>{session.type}</p>
                                                                <p style={{ marginRight: 10 }}>{session.price}</p>

                                                            </div>
                                                            {isAdmin ?
                                                                <>
                                                                    <button
                                                                        style={{
                                                                            backgroundColor: session.isRegistered && 'lightgray'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            // props.handleNext()
                                                                            registerToEvent(session)
                                                                        }}
                                                                        className="themesflat-button bg-accent"
                                                                    >
                                                                        {session.isRegistered ?
                                                                            "Confirmed at " + new Date(session.isRegistered).toUTCString() : "Confirm Attendance"
                                                                        }
                                                                    </button>
                                                                </>
                                                                :
                                                                <>
                                                                    {session.isRegistered &&
                                                                        "Confirmed at " + new Date(session.isRegistered).toUTCString()
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        } */}
                                                    </div>

                                                </div>
                                                :
                                                <div style={{
                                                    borderRadius: '2rem',
                                                    background: "rgb(0,173,78)",
                                                    background: "linear-gradient(180deg, rgba(0,173,78,1) 0%, rgba(1,87,58,1) 100%)",
                                                    maxWidth: 770,
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                    padding: '1rem'
                                                }}>
                                                    <div>
                                                        <div className='row justify-content-md-center'>
                                                            <div className='col-12 mt-5'>
                                                                <img style={{ width: '100%', maxWidth: '100%' }} src={"https://grabnext2024.com/wp-content/uploads/2024/02/grab-heading-logo.svg"} alt="board" />
                                                            </div>
                                                        </div>


                                                        <div className='justify-content-md-center mt-5'>
                                                            <h3 style={{ textAlign: 'center', color: 'white', lineHeight: '25px' }}>Invalid QR Code or not registered</h3>
                                                        </div>

                                                        <div className='row justify-content-md-center'>
                                                            <div className='col-3'></div>
                                                            <div className='col-6 mt-5'>
                                                                <img style={{ width: '100%', maxWidth: '100%' }} src={"https://www.pikpng.com/pngl/b/192-1922041_qr-code-icon-white-clipart.png"} alt="board" />
                                                            </div>
                                                            <div className='col-3'></div>
                                                        </div>

                                                        <div className='justify-content-md-center mt-5'>
                                                            <p style={{ textAlign: 'center', color: 'white', lineHeight: '25px' }}>Kindly assist the participant to the registration area</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />

                    </div>
                </div>
                {/* <Footer /> */}
                {/* {
                        headers.map((data, idx) => (
                            <BottomBar data={data} key={idx} />
                        ))
                    } */}
            </div>
        </div>
    );
}
