/* eslint-disable react/button-has-type */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import env from 'react-dotenv';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { registrationFields } from './constant';

export default function StepTwo(props) {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [message3] = useState('');
  const [documentURL, setDocumentURL] = useState(null);
  const [setDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [codeResult, setCodeResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [isEmailLoading, setIsEmailLoading] = useState(false)

  const [userData, setUserData] = useState(null)

  const [prcnumber, setPrcNumber] = useState(null);
  const [pdsIdNumber, setPdsIdNumber] = useState(null);
  const [pmaNumber, setPmaNumber] = useState(null);

  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(async () => {
  //     checkEmail(props.email)
  // }, [props.email])
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'))

    if (userData) {
      if (!userData.admin) {
        window.location = '/login'
      }
    } else {
      window.location = '/login'
    }

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fields?.includes('upload') || fields?.includes('upload_prc')) {
      if (!documentURL) {
        NotificationManager.error('Document is required.', '', 5000);
        return;
      }
    }

    // CHECK ERROR MESSAGES
    if (error) {
      NotificationManager.error('Email is not valid. Check email field.', '', 5000);
      return;
    }
    if (message3 !== '') {
      NotificationManager.error('PRC Number is not valid.', '', 5000);
      return;
    }


    if (pdsIdNumber) {
      props.setPdsNumber(pdsIdNumber);
    }

    if (prcnumber) {
      props.setPrcNumber(prcnumber);
    }

    if (pmaNumber) {
      props.setPmaNumber(pmaNumber);
    }

    props.setCountry('Philippines');
    props.setLetterUrl(documentURL);


    if (fields?.includes('fellowship')) {
      setOpen(true);
    } else {
      props.handleNext('step_2_5');
    }


    // if (props.selectedOptions.includes('speaker')
    //   || props.selectedOptions.includes('board')
    //   || props.selectedOptions.includes('member')
    // ) {
    //   setOpen(true);
    // } else {
    //   props.handleNext('step_2_5');
    // }

  };

  const handlegetAllData = () => {
    setCodeResult(null)

    setIsLoading(true)
    const str = `${env.API_URL}/metadata/getalldata`
    axios.get(str).then((res) => {
      setIsLoading(false)
      try {
        NotificationManager.success('Successfully extracted information', '', 5000);
        setCodeResult(res.data.data)
        return true;
      } catch (e) { }
    }).catch((e) => {
      setIsLoading(false)
      return false;
    });
  };

  const handlegetFellowshipData = () => {
    setCodeResult(null)

    setIsLoading(true)
    const str = `${env.API_URL}/metadata/pds/getfellowship`
    axios.get(str).then((res) => {
      setIsLoading(false)
      try {
        NotificationManager.success('Successfully extracted information', '', 5000);
        setCodeResult(res.data)
        return true;
      } catch (e) { }
    }).catch((e) => {
      setIsLoading(false)
      return false;
    });
  };

  const checkEmail = (e) => {
    setIsEmailLoading(true)
    if (e !== '') {
      // CREATE SOURCE
      const body = {
        email: e.toLowerCase(),
      };
      const accessToken = localStorage.getItem('accessToken')

      axios.post(`${env.API_URL}/metadata/email`, body, {
        headers: {
          'authorization': 'Bearer ' + accessToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }).then((res) => {
        setIsEmailLoading(false)

        try {
          if (res.data.error) {
            setMessage('Email is registered');
            setUserData(res.data.userdata)
            setError(false);

            return false;
          }
          setMessage('Email is not registered. You need to register this user');
          setError(true);

          return true;
        } catch (e) { }
      }).catch((e) => {
        setIsEmailLoading(false)
        setMessage(e.response.data.error);
        setUserData({ user: e.response.data.userdata })

        const url = `${env.API_URL}/metadata/verifywithid/` + e.response.data.userdata.id
        // CHECK IF ALREADY REGISTERED
        axios.get(url).then((dt) => {
          setUserData(dt.data)
        }).catch((err) => {
          // NotificationManager.error("You are not registered to the PDS Convention. Register for the event first before reserving workshops.", '', 5000);
        })

        setError(true);
        return false;
      });
    }
  };

  const handleSendEmail = (e) => {
    if (e !== '') {
      setIsLoading(true)

      const str = `${env.API_URL}/metadata/sendallconfirmation/` + e
      axios.get(str).then((res) => {
        setIsLoading(false)

        try {
          props.setEmail('');
          if (res.data.error) {
            setMessage(res.data.error);
            setError(true);
            NotificationManager.error('Could not send email to ' + e, '', 5000);
            return false;
          }
          NotificationManager.success('Email confirmation sent to ' + e, '', 5000);

          setMessage(res.data.message);
          setError(false);
          return true;
        } catch (e) { }
      }).catch((e) => {
        setIsLoading(false)

        setMessage(e.response.data.error);
        setError(true);
        return false;
      });
    }
  };

  const handleGenerateID = (e) => {

    if (e !== '') {
      setIsLoading(true)

      const str = `${env.API_URL}/metadata/generateallid/` + e
      axios.get(str).then((res) => {
        setIsLoading(false)

        try {
          props.setEmail('');
          if (res.data.error) {
            setMessage(res.data.error);
            setDocumentURL(null);
            setError(true);
            NotificationManager.error('Could not generate ID for ' + e, '', 5000);
            return false;
          }
          setMessage(res.data.message);
          setDocumentURL(res.data.imageLink)
          setError(false);
          NotificationManager.success('Generated ID for ' + e, '', 5000);
          return true;
        } catch (e) { }
      }).catch((e) => {
        setIsLoading(false)

        setMessage(e.response.data.error);
        setError(true);
        return false;
      });
    }
  };

  const RenderWorkshop = (array) => {
    return (
      <>
        {
          array?.sort((workshopA, workshopB) => {
            if (workshopA.day < workshopB.day) {
              return -1;
            }
            if (workshopA.day > workshopB.day) {
              return 1;
            }
            return 0;
          }).map((session, index) => {
            return <div className='col-12 card' key={index}
              style={{
                marginTop: 10,
                padding: 20,
                pointerEvents: session.isRegistered ? 'none' : 'all',
                opacity: session.isRegistered ? 0.5 : 1,
              }}>

              <div>
                <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>{session.title}</p>
                <p style={{ marginRight: 10 }}>{session.assignment.toUpperCase()}</p>
                <p style={{ marginRight: 10 }}>{session.startTime} - {session.endTime}</p>
              </div>
              {session.isRegistered &&
                "Confirmed at " + new Date(session.isRegistered).toLocaleString()
              }
            </div>
          })
        }
      </>
    )
  }


  return (
    <form id="wizard" onSubmit={handleSubmit}>
      <NotificationContainer />
      <Modal
        closeOnOverlayClick={false}
        open={isLoading}
        showCloseIcon={false}
        center>
        <div style={{ height: '150px', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <h6 style={{ color: 'black', margin: 10 }}>LOADING...</h6>
          <Dots color={'black'} size={50} />
        </div>
      </Modal>

      <Modal
        open={open}
        onClose={() => { setOpen(false) }}
        center
      >
        <div style={{ margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

          {/* <h2 style={{ textAlign: 'center', lineHeight: 1.5, marginTop: '2rem' }}>Would you like to attend the Fellowship Night ?</h2>
          <div className="actions" style={{ display: "flex", marginTop: '2rem', bottom: 0, right: 0 }}>
            <button
              onClick={(e) => {
                setOpen(false)
                props.setFellowship(false)
                props.handleNext('step_2_5');
              }}
              className="button-next"
              style={{ marginRight: 10, width: '120px', borderRadius: 5 }}
            >
              No
            </button>
            <button
              onClick={(e) => {
                setOpen(false)
                props.setFellowship(true)
                props.handleNext('step_2_5');
              }}
              className="button-next-active"
              style={{ width: '120px', borderRadius: 5 }}
            >
              Yes
            </button>
          </div> */}

          <h2 style={{ textAlign: 'center', lineHeight: 1.5, marginTop: '2rem' }}>Confirm details?</h2>
          <div className="actions" style={{ display: "flex", marginTop: '2rem', bottom: 0, right: 0 }}>
            <button
              onClick={(e) => {
                setOpen(false)
              }}
              className="button-next"
              style={{ marginRight: 10, width: '120px', borderRadius: 5 }}
            >
              Back
            </button>
            <button
              onClick={(e) => {
                setOpen(false)
                props.setFellowship(false)
                props.handleNext('step_2_5');
              }}
              className="button-next-active"
              style={{ width: '120px', borderRadius: 5 }}
            >
              Ok
            </button>
          </div>

        </div>
      </Modal>

      <div className={props.currentForm === 0 ? 'multisteps-form__panel js-active' : 'multisteps-form__panel'} data-animation="slideHorz">
        {/* div 2 */}
        <div className="wizard-forms">
          <div className="inner pb-100 clearfix">
            <div className="form-content pera-content" style={{ width: "100%" }}>
              <div className="step-inner-content">
                <span className="step-no bottom-line">Administrator</span>
                {/* <div className="step-progress float-right">
                  <span>2 of 4 completed</span>
                  <div className="step-progress-bar">
                    <div className="progress">
                      <div className="progress-bar" />
                    </div>
                  </div>
                </div> */}

                <div className="form-inner-area">
                  <p>Enter Participant Email</p>
                  <div className="row">
                    <div className="col-12">
                      <input
                        value={props.email}
                        onChange={(e) => {
                          setMessage(null);
                          setDocumentURL(null);
                          setCodeResult(null)
                          setUserData(null)
                          function validEmail(e) {
                            var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
                            return String(e).search(filter) !== -1;
                          }

                          if (validEmail(e.target.value)) {
                            checkEmail(e.target.value)
                          } else {
                            setMessage('Invalid Email')
                            setError(true)
                          }

                          props.setEmail(e.target.value);
                        }}
                        // disabled={props.email}
                        type="email"
                        name="email"
                        id="email"
                        className="form-control required"
                        minLength="5"
                        placeholder="Email *"
                        required
                        autoComplete="off"
                      />
                      {isEmailLoading &&
                        <div className='row' style={{ paddingLeft: '2rem' }}>
                          <p style={{ marginRight: '1rem' }}>Looking for email</p>
                          <Dots color={'black'} size={20} />
                        </div>
                      }


                      {/* <input disabled={props.email || props.pdsNumber} onChange={(e) => { props.setLastName(e.target.value); }} value={props.lastName} type="text" name="last_name" id="last_name" className="form-control required" minLength="2" placeholder="Last Name *" required autoComplete="off" /> */}
                      {/* <input disabled={props.pdsNumber} onChange={(e) => { props.setMiddleName(e.target.value); }} type="text" name="middle_name" id="middle_name" className="form-control required" placeholder="MI " /> */}
                      {/* <input onChange={(e) => { props.setProSuffix(e.target.value); }} type="text" name="proffesional_suffix" id="proffesional_suffix" className="form-control" minLength="2" placeholder="Professional Suffix " /> */}
                    </div>


                  </div>
                  <p style={{ color: error && 'green', marginBottom: 10 }}>{message} {documentURL &&
                    <a href={documentURL}>Click here to download the ID</a>
                  }</p>


                  <div className="row">

                    <div className="col-6">
                      <button
                        style={{
                          height: '100%', width: '100%',
                          opacity: !props.email | message !== 'Email Already Registered' ? 0.5 : 1,
                          pointerEvents: !props.email | message !== 'Email Already Registered' ? 'none' : 'all',

                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSendEmail(props.email)
                          // if (fields[0])
                          //   props.setCategory(fields[0])
                          // e.preventDefault();
                          // props.handleNext()
                        }}
                        className="themesflat-button bg-accent"
                      >
                        Send Confirmation Email

                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        style={{
                          height: '100%', width: '100%',
                          opacity: !props.email | message !== 'Email Already Registered' ? 0.5 : 1,
                          pointerEvents: !props.email | message !== 'Email Already Registered' ? 'none' : 'all',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleGenerateID(props.email)
                          // if (fields[0])
                          //   props.setCategory(fields[0])
                          // e.preventDefault();
                          // props.handleNext()
                        }}
                        className="themesflat-button bg-accent"
                      >
                        Generate ID

                      </button>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-6">
                      <button
                        style={{ height: '100%', width: '100%' }}
                        onClick={(e) => {
                          e.preventDefault();
                          handlegetAllData()
                        }}
                        className="themesflat-button bg-accent"
                      >
                        Export all data into text

                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        style={{ height: '100%', width: '100%' }}
                        onClick={(e) => {
                          e.preventDefault();
                          handlegetFellowshipData()
                        }}
                        className="themesflat-button bg-accent"
                      >
                        Get all Fellowship

                      </button>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 20 }}>

                    {codeResult &&
                      <>
                        <textarea id="w3review" name="w3review" rows="20" cols="50">
                          {JSON.stringify(codeResult)}
                        </textarea>

                        <p>Copy all text and click here go to <a href='https://products.aspose.app/cells/conversion/json-to-excel'>Aspose.com</a> to generate excel</p>

                      </>
                    }
                  </div>
                </div>

                {userData &&
                  <div style={{ padding: '2rem' }} className='justify-content-md-center mt-5 card'>
                    <p style={{ marginTop: 5, textAlign: 'left' }}> Name: {userData?.user?.firstName} {userData?.user?.lastName}</p>
                    <p style={{ marginTop: 5, textAlign: 'left' }}> Email: {userData?.user?.email}</p>
                    {userData?.user?.pdsNumber !== "" &&
                      <p style={{ marginTop: 5, textAlign: 'left' }}> PDS Number: {userData?.user?.pdsNumber}</p>
                    }
                    <p style={{ marginTop: 5, textAlign: 'left' }}> Membership Type: {userData?.user?.categoryId}</p>

                    <p style={{ marginTop: 5, textAlign: 'left', color: userData?.user?.businessMeeting ? 'green' : 'red' }}> {userData?.user?.businessMeeting ? '✔ Attending business meeting' : '✖ Not attending business meeting'}</p>


                    <p style={{ marginTop: 5, textAlign: 'left', color: userData?.user?.fellowship ? 'green' : 'red' }}> {userData?.user?.fellowship ? '✔ Attending fellowship night' : '✖ Not attending fellowship night'}</p>

                    {/* {isAdmin &&
                                                    <> */}
                    {userData?.user?.accompany_first_name &&
                      <div className='col-12 card'
                        style={{
                          marginTop: 10,
                          padding: 20,
                        }}>

                        <div>
                          <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>Accompanied by:</p>
                          <p style={{ marginRight: 10 }}>{userData?.user?.accompany_first_name} {userData?.user?.accompany_last_name}</p>
                          <p style={{ marginRight: 10 }}>{userData?.user?.accompany_email}</p>
                        </div>
                      </div>
                    }


                    {userData?.precon &&
                      <div className="session-group" style={{ marginTop: 40 }}>
                        {userData.precon.length > 0 &&
                          <div className='card-deck' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                            <h3>Precon (November 05, 2023)</h3>
                          </div>
                        }
                        <div className='card-deck justify-content-md-center' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                          {userData.precon.map((session, index) => {
                            return <div className='col-12 card' key={index}
                              style={{
                                marginTop: 10,
                                padding: 20,
                                pointerEvents: session.isRegistered ? 'none' : 'all',
                                opacity: session.isRegistered ? 0.5 : 1,
                              }}>

                              <div>
                                <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>{session.title}</p>
                                <p style={{ marginRight: 10 }}>{session.assignment} Room</p>
                                <p style={{ marginRight: 10 }}>{session.startTime} - {session.endTime}</p>
                                <p style={{ marginRight: 10 }}>{session.type}</p>
                                <p style={{ marginRight: 10 }}>{session.price}</p>

                              </div>
                              <>
                                {session.isRegistered &&
                                  "Confirmed at " + new Date(session.isRegistered).toLocaleString()
                                }
                              </>
                            </div>
                          })}
                        </div>
                      </div>
                    }

                    {userData?.main &&
                      <div className="session-group" style={{ marginTop: 40 }}>
                        <div className='card-deck' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                          <h3>Day 1 (November 06, 2023)</h3>
                        </div>
                        <div className='card-deck justify-content-md-center' style={{ marginLeft: '2rem', marginRight: '2rem' }}>

                          {RenderWorkshop([...userData?.main.filter((item) => item.assignment === "day 1"), ...userData?.workshop.filter((item) => item.assignment === "day 1")])}
                        </div>
                      </div>
                    }


                    {userData?.workshop &&
                      <div className="session-group" style={{ marginTop: 40 }}>
                        {userData.workshop.length > 0 &&
                          <div className='card-deck' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                            <h3>Day 2 (November 07, 2023)</h3>
                          </div>
                        }
                        <div className='card-deck justify-content-md-center' style={{ marginLeft: '2rem', marginRight: '2rem' }}>

                          {RenderWorkshop([...userData?.main.filter((item) => item.assignment === "day 2"), ...userData?.workshop.filter((item) => item.assignment === "day 2")])}
                        </div>
                      </div>
                    }


                  </div>
                }


                <br />
                {/* <p>Under RA 10173 of the Republic of the Philippines, all personal information collected, stored and processed by this site shall be duty bound to observe and respect your privacy rights.</p> */}
              </div>
            </div>
          </div>
          <div className="actions">
            <ul>
              <li>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location = '/register'
                  }}
                  className="themesflat-button bg-accent js-btn-prev"
                >
                  Register to Convention

                </button>
              </li>
              <li>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location = '/precon'
                  }}
                  className="themesflat-button bg-accent"
                >
                  Register for Precon

                </button>
              </li>
              <li>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location = '/fellowship'
                  }}
                  className="themesflat-button bg-accent js-btn-prev"
                >
                  Register to Business Meeting & Fellowship

                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  );
}
