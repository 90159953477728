import React, { useState, useEffect } from "react";
import axios from 'axios';
import env from 'react-dotenv';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export default function StepTwoSession(props) {
    const [workshops, setWorkshops] = useState([])
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        getWorkshops()
    }, [])

    const getWorkshops = () => {
        axios.get(`${env.API_URL}/metadata/workshops`)
            .then((res) => {
                setWorkshops(res.data)
            }).catch((e) => {
                NotificationManager.error('Please try again later', '', 5000);
            })
    }

    return (
        <div className={props.currentForm === 0.5 ? 'multisteps-form__panel js-active' : 'multisteps-form__panel'} data-animation="slideHorz">
            <NotificationContainer />

            <div className="wizard-forms">
                <div className="inner pb-30 clearfix">
                    <div className="form-content pera-content">
                        <div className="step-inner-content">
                            <span className="step-no bottom-line">Step 2</span>
                            <div className="step-progress float-right">
                                <span>2 of 4 completed</span>
                                <div className="step-progress-bar">
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: '40%' }} />
                                    </div>
                                </div>
                            </div>
                            <h2>Please select session you want to attend</h2>
                            <p>(You cannot attend sessions that have 0 slots remaining)</p>
                            <div className="form-inner-area">
                                <div className="row">

                                    <div className="col-lg-6 col-xs-12 mb-5">
                                        <div className="session-wrapper">
                                            <h3>Day 1</h3>
                                            <p>Focus Group Discussion</p>
                                            <p>8:00 AM - 10:00 AM</p>

                                            <div className="session-group">
                                                {workshops.filter(item => item.assignment === "day 1").map((workshop, index) => {
                                                    return (
                                                        <div className="session-form" key={index}>
                                                            <div className="col-2">
                                                                <input
                                                                    style={{
                                                                        width: 50,
                                                                        height: 50
                                                                    }}
                                                                    id={index}
                                                                    type="radio"
                                                                    className="radio"
                                                                    name={workshop.assignment}
                                                                    // disabled={workshop.slots <= 0}
                                                                    onChange={(e) => {
                                                                        const filtered = selected.filter(item => item.assignment !== workshop.assignment)

                                                                        setSelected([
                                                                            ...filtered,
                                                                            workshop
                                                                        ])
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-10 py-3">
                                                                <p> {workshop.title} </p>
                                                                <p style={{ color: workshop.slots <= 0 && 'red' }}>(Slots Remaining: {workshop.slots >= 0 ? workshop.slots : 0 })</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12 mb-5" >
                                        <div className="session-wrapper">
                                            <h3>Day 2</h3>
                                            <p>Symposium</p>
                                            <p>8:00 AM - 12:00 AM</p>

                                            <div className="session-group">
                                                {workshops.filter(item => item.assignment === "day 2").map((workshop, index) => {
                                                    return (
                                                        <div className="session-form" key={index}>
                                                            <div className="col-2">
                                                                <input
                                                                    style={{
                                                                        width: 50,
                                                                        height: 50
                                                                    }}
                                                                    id={index}
                                                                    type="radio"
                                                                    className="radio"
                                                                    name={workshop.assignment}
                                                                    // disabled={workshop.slots <= 0}
                                                                    onChange={(e) => {
                                                                        const filtered = selected.filter(item => item.assignment !== workshop.assignment)

                                                                        setSelected([
                                                                            ...filtered,
                                                                            workshop
                                                                        ])
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-10 py-3">
                                                                <p> {workshop.title} </p>
                                                                <p style={{ color: workshop.slots <= 0 && 'red' }}>(Slots Remaining: {workshop.slots >= 0 ? workshop.slots : 0 })</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <ul>
                        <li>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.handleBack('step_2');
                                }}
                                className="themesflat-button bg-accent js-btn-prev"
                            >
                                BACK

                            </button>
                        </li>
                        <li>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.setSessions(selected);
                                    props.handleNext('step_3');
                                }}
                                className={selected.length < 2 ? 'button-next' : 'themesflat-button bg-accent'}
                                disabled={selected.length < 2}
                            >
                                NEXT

                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}