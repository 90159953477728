import React, { useEffect, useCallback } from "react";

import env from 'react-dotenv';
import QRCode from "react-qr-code";
import { toJpeg } from 'html-to-image';
// import html2canvas from 'html2canvas';

import { registrationFields } from './constant';



export default function StepFive(props) {
    const [showPrecon, setShowPrecon] = React.useState(false);

    const downloadImage = () => {
        // html2canvas(document.getElementById('registrationID'), {useCORS: true, allowTaint: true, proxy: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685334934/PDS/id_fhcqbd.jpg'}).then(function(canvas) {
        //     console.log('useigisuhgiose', canvas)
        //     document.body.appendChild(canvas);

        //     var a = document.createElement('a');
        //     a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        //     a.download = `${props.data.certName}.jpeg`
        //     a.click();

        // });
        toJpeg(document.getElementById('registrationID'), { quality: 0.95, canvasWidth: 849, canvasHeight: 1416 })
            .then(function (dataUrl) {

                var link = document.createElement('a');
                link.download = `${props.data.certName}.jpeg`;
                link.href = dataUrl;
                link.click();
            });
    }

    // console.log('props.data.password', props.password)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const uploadImage = useCallback(() => {
        toJpeg(document.getElementById('registrationID'), { quality: 0.95, canvasWidth: 849, canvasHeight: 1416 })
            .then(function (dataUrl) {
                const data = new FormData();
                data.append('file', dataUrl);
                data.append("upload_preset", "pds_uploads")
                fetch('https://api.cloudinary.com/v1_1/duuldyiu5/image/upload', {
                    method: 'post',
                    body: data,
                })
                    .then((resp) => resp.json())
                    .then((data) => {
                        fetch(`${env.API_URL}/metadata/user/${props.data.createdUser.id}`, {
                            method: 'PUT',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                firstName: props.data.firstName,
                                lastName: props.data.lastName,
                                email: props.data.email,
                                password: props.password,
                                registrationId: data.url,
                            })
                        })
                            .then((resp) => resp.json())
                            .then((data) => {
                                console.log(data)
                                localStorage.setItem('accessToken', data.accessToken)
                            })
                            .catch((err) => console.log(err));

                    })
                    .catch((err) => console.log(err));
            });

    });

    useEffect(() => {
        if (props.currentForm === 4 && !!props.data.createdUser) {
            // uploadImage();
        }

        if (props.currentForm === 4) {
            const tempFields = registrationFields[props?.data?.selectedOptions.join('_')]
            if (tempFields?.includes('precon')) {
                setShowPrecon(true)
            }
        }

    }, [props, props.data.createdUser, uploadImage]);

    return (
        <div className={props.currentForm === 4 ? "multisteps-form__panel js-active" : "multisteps-form__panel"} data-animation="slideHorz">
            <div className="wizard-forms">
                <div className="inner pb-50 clearfix">
                    <div className="form-content pera-content" style={{ width: '100%' }}>
                        <div className="step-inner-content">
                            <span className="step-no bottom-line">Step 4</span>
                            <div className="step-progress float-right">
                                <span>4 of 4 completed</span>
                                <div className="step-progress-bar">
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: '100%' }}></div>
                                    </div>
                                </div>
                            </div>
                            <h4 style={{marginTop: 20}}>Registration Complete</h4>
                            <p>We have emailed you your login credentials. Below is your official convention ID.

                                </p>
                            {/* <p>We have emailed you your login credentials. 
                                Click
                                <span
                                    style={{
                                        color: '#00a8ff',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        downloadImage();
                                    }}
                                >  here </span>
                                to download your Registration ID
                                </p> */}
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }} >
                    <div style={{ width: 566, height: 944 }} id="registrationID">
                        <div className="registrationID" style={{backgroundImage: `url(${props?.data?.createdUser?.registrationId})`}}>
                            {/* <div style={{ width: '200px', paddingTop: 410 }} >
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={`https://www.pdsconvention2023.com/verify/${props?.data?.createdUser?.asd}`}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <div style={{ paddingTop: '20px', paddingHorizontal: 20 }}>
                                <h2 style={{lineHeight: 1}}>{props.data.certName}</h2>
                                <br />
                                <h3>{props.data.category}</h3>
                               
                            </div> */}
                        </div>
                    </div>
                </div>


                {showPrecon &&
                    <div className="precon-wrapper">
                        <h3>Do you also want to register to the Pre-Con Workshop?</h3>
                        <div className="actions">

                            <ul>
                                <li>
                                    <button onClick={() => {
                                        window.location = '/';
                                    }} className="button-next">Go Home</button>
                                </li>
                                <li>
                                    <button onClick={() => {
                                        window.location = '/precon';
                                    }} className="button-next-active">Proceed</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
