import React, { useState, useEffect } from 'react';
import axios from 'axios';
import env from "react-dotenv";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


export default function StepTwo(props) {
    const [message, setMessage] = useState("")
    const [error, setError] = useState(false)
    const [password, setPassword] = useState("")

    // useEffect(async () => {
    //     checkEmail(props.email)
    // }, [])

    const handleSubmit = async e => {
        // e.preventDefault();
        props.setEmail(document.getElementById("email").value)
        props.setPassword(password)
        loginUser()
    }

    const loginUser = () => {
        props.setIsLoading(true)
        // NotificationManager.success("Login", '', 5000);

        // CREATE SOURCE
        let options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Basic ${Buffer.from(env.PAYMONGO_KEY + ":Luis Ramirez").toString(`base64`)}`
            },
            body: JSON.stringify({
                "email": props.email,
                "password": password,
            })
        };

        fetch(env.API_URL + "/metadata/user/login", options)
            .then(response => response.json())
            .then(response => {
                props.setIsLoading(false)
                if (response.userData.admin) {
                    if (response.error) {
                        NotificationManager.error("Could not login. " + response.error, '', 5000);
                        return
                    }
                    localStorage.setItem('accessToken', response.accessToken)
                    localStorage.setItem('userData', JSON.stringify(response.userData))
                    window.location = '/verify/scan'
                } else {
                    NotificationManager.error("You are not an admin. Contact your administrators.", '', 5000);
                }
            })
            .catch(err => {
                props.setIsLoading(false)
                console.error(err)
            });
    }

    return (
        <form id="wizard">
            <NotificationContainer />

            <div className={props.currentForm === 1 ? "multisteps-form__panel js-active" : "multisteps-form__panel"} data-animation="slideHorz" >
                {/* div 2 */}
                <div style={{ borderRadius:'2rem', backgroundColor: "#156338" }} className="wizard-forms">
                    <div className="inner pb-100 clearfix">
                        <div className="form-content pera-content w-100">
                            <div className="step-inner-content" >
                                {/* <div className='row justify-content-md-center'>
                                    <div className='col-1'></div>
                                    <div className='col-10 mt-5' style={{
                                        "display": "flex",
                                        "justify-content": "center"
                                    }}>
                                        <img style={{ width: '100%' }} src={"https://res.cloudinary.com/duuldyiu5/image/upload/v1698833351/PDS/logo_pds.png"} alt="board" />
                                    </div>
                                    <div className='col-1'></div>
                                </div> */}
                                <div id='headerimagelogo'></div>

                                <div className="form-inner-area">
                                    <h2 style={{color: "white"}}>Login</h2>
                                    <p>Input your information.</p>
                                    {/* <input onChange={(e) => { props.setCertName(e.target.value) }} type="text" name="cert_name" id="cert_name" className="form-control required" minLength="2" placeholder="Certificate Name *" required autocomplete="off" /> */}
                                    <input style={{
                                        fontSize: "x-large",
                                        padding: '1rem',
                                        backgroundColor: "white"
                                    }} value={props.email} onChange={(e) => {
                                        // if (props.isLocal === "local") {
                                        function validEmail(e) {
                                            var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
                                            return String(e).search(filter) != -1;
                                        }
                                        if (validEmail(e.target.value)) {
                                            // checkEmail(e.target.value)
                                            setMessage('')
                                        } else {
                                            setMessage('Invalid Email')
                                            setError(true)
                                        }
                                        props.setEmail(e.target.value)
                                        // }

                                    }} type="email" name="email" id="email" className="form-control required" minLength="5" placeholder="Email *" required autocomplete="off" />
                                    <p style={{ color: error && 'red', marginBottom: 10 }}>{message}</p>
                                    <input style={{
                                        fontSize: "x-large",
                                        padding: '1rem',
                                        backgroundColor: "white"
                                    }} onChange={(e) => {
                                        setPassword(e.target.value)
                                        props.setPassword(e.target.value)
                                    }} type="password" name="password" id="password" className="form-control required" minLength="2" placeholder="Password *" required autocomplete="off" />
                                </div>
                                <br />

                                <div className="actions" style={{paddingRight: 0}}>
                                    <ul>
                                        <li>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                // props.handleNext()
                                                handleSubmit()
                                                // window.location = '/'
                                            }} className="themesflat-button bg-accent">Login</button>
                                        </li>
                                        {/* <li><span className="js-btn-next" title="NEXT">NEXT <i className="fa fa-arrow-right"></i></span></li> */}
                                    </ul>
                                </div>
                                {/* <p>If you do not have an account click on 'Register' to create your account.</p> <br /> */}
                            </div>
                        </div>
                    </div>
                    {/*<!-- /.inner -->*/}

                </div>
            </div>
        </form>
    );
}

