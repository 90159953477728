import React, { useEffect, useCallback } from "react";

import env from 'react-dotenv';
import QRCode from "react-qr-code";
import { toJpeg } from 'html-to-image';
// import html2canvas from 'html2canvas';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import moment from 'moment'

import { registrationFields } from './constant';
import Modal from 'react-modal';


export default function StepFive(props) {
    const [showPrecon, setShowPrecon] = React.useState(false);

    const downloadImage = () => {
        // html2canvas(document.getElementById('registrationID'), {useCORS: true, allowTaint: true, proxy: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685334934/PDS/id_fhcqbd.jpg'}).then(function(canvas) {
        //     console.log('useigisuhgiose', canvas)
        //     document.body.appendChild(canvas);

        //     var a = document.createElement('a');
        //     a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        //     a.download = `${props.data.certName}.jpeg`
        //     a.click();

        // });
        toJpeg(document.getElementById('registrationID'), { quality: 0.95, canvasWidth: 849, canvasHeight: 1416 })
            .then(function (dataUrl) {

                var link = document.createElement('a');
                link.download = `${props.data.certName}.jpeg`;
                link.href = dataUrl;
                link.click();
            });
    }

    // console.log('props.data.password', props.password)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const uploadImage = useCallback(() => {
        toJpeg(document.getElementById('registrationID'), { quality: 0.95, canvasWidth: 849, canvasHeight: 1416 })
            .then(function (dataUrl) {
                const data = new FormData();
                data.append('file', dataUrl);
                data.append("upload_preset", "pds_uploads")
                fetch('https://api.cloudinary.com/v1_1/duuldyiu5/image/upload', {
                    method: 'post',
                    body: data,
                })
                    .then((resp) => resp.json())
                    .then((data) => {
                        fetch(`${env.API_URL}/metadata/user/${props.data.createdUser.id}`, {
                            method: 'PUT',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                firstName: props.data.firstName,
                                lastName: props.data.lastName,
                                email: props.data.email,
                                password: props.password,
                                registrationId: data.url,
                            })
                        })
                            .then((resp) => resp.json())
                            .then((data) => {
                                console.log(data)
                                localStorage.setItem('accessToken', data.accessToken)
                            })
                            .catch((err) => console.log(err));

                    })
                    .catch((err) => console.log(err));
            });

    });

    useEffect(() => {
        if (props.currentForm === 4 && !!props.data.createdUser) {
            // uploadImage();
        }

        if (props.currentForm === 4) {
            const tempFields = registrationFields[props?.data?.selectedOptions.join('_')]
            if (tempFields?.includes('precon')) {
                setShowPrecon(true)
            }
        }

    }, [props, props.data.createdUser, uploadImage]);


    const handleDownload = () => {
        // Create an anchor element
        const link = document.createElement('a');
        link.href = props?.data?.createdUser?.registrationId;
        link.download = "GrabNextId"; // Set the desired file name
        link.click(); // Trigger the download
    };

    const addCalbutton = ({ children, onClick }) => {
        return (
            <>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
                    className="w-100 justify-content-center align-items-center">
                    <a
                        style={{ color: "white", cursor: "pointer" }}
                        // onClick={(e) => {
                        //     e.preventDefault();

                        // }}
                        class="buttonhx ribbon-outset border">
                        <button
                        style={{backgroundColor: "transparent"}}
                            // className={"buttonhx ribbon-outset border"}
                            onClick={onClick}
                        >
                            Add to Calendar
                        </button>

                    </a>


                </div>
            </>
        )
    }
    const dropDown = ({ children }) => {
        const dropdownStyles = {
            padding: 10,
            display: "flex",
            flexDirection: "column",
            border: '1px solid #E5E5E5',
            borderTop: "none",
            width: 300,
            backgroundColor: "#FFF",
            margin: 0
        };

        return (
            <div style={dropdownStyles}>
                {children}
            </div>
        );
    }

    const modalCal = ({ children,
        isOpen,
        onRequestClose }) => {
        const containerStyles = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "space-between",
            width: 450,
            height: 375,
            margin: "0 auto",
            top: "50%",
            left: 0,
            right: 0,
            transform: "translateY(-50%)",
            position: "absolute",
            boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.3)",
            padding: 30,
            backgroundColor: "#FFF",
        }
        return (
            <Modal
                className={containerStyles}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                shouldCloseOnOverlayClick={true}
            >
                <h2>Add to Calendar</h2>
                <div>{children}</div>
                {/* <Button onClick={onRequestClose}>Cancel</Button> */}
            </Modal>
        );
    }
    const AddToCalendarModal = AddToCalendarHOC(addCalbutton, dropDown);

    const startDatetime = moment("Thursday, April 25, 2024 9:00:00 AM").utc();
    const endDatetime = startDatetime.clone().add(2, 'hours');
    const duration = moment.duration(endDatetime.diff(startDatetime)).asHours();
    const event = {
        description: 'This year, merchant-partners can expect and learn how they can sustainably grow their business further with Grab, how dine-in and the latest development in technology has affected the online delivery landscape, and the different business solutions and strategies they can utilize to stay ahead in the marketplace.',
        duration,
        endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
        location: 'THE FIFTH AT ROCKWELL, EVENTS HALL',
        startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
        title: 'GrabNEXT 2023: Future-Proofing Your Business for Sustainable Growth',
    }


    return (
        <form id="wizard" >
            <div
                style={{
                    borderRadius: '2rem',
                    background: "rgb(0,173,78)",
                    background: "linear-gradient(180deg, rgba(0,173,78,1) 0%, rgba(1,87,58,1) 100%)"
                }}
                className={props.currentForm === 4 ? "multisteps-form__panel js-active" : "multisteps-form__panel"} data-animation="slideHorz">

                {/* div 2 */}
                <div className="wizard-forms">
                    <div className="inner clearfix">
                        <div style={{
                            width: '100%'
                        }}
                            className="pera-content">
                            <div className="step-inner-content">
                                {/* <div id='headerimage'></div> */}
                                <div>
                                    <div className="form-content">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 style={{ textAlign: "center", color: "white" }}>Hi {props.data.firstName}</h1>
                                            </div>
                                            <div className="col-12">
                                                <p style={{ textAlign: "center", color: "white" }}>Present the QR below for the scanning to confirm your attendance on the event</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div style={{ marginTop: '1rem', marginBottom: '1rem', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }} >
                                                <div style={{ width: '100%' }} id="registrationID">
                                                    <div className="registrationID" style={{ backgroundSize: "contain", backgroundImage: `url(${props?.data?.createdUser?.registrationId})` }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {/* <a style="color: #ffffff; cursor: pointer;" download={props?.data?.createdUser?.registrationId}> */}
                                                <p onClick={handleDownload} style={{ textAlign: "center", textDecoration: "underline", cursor: "pointer" }}>Download QR.</p>
                                                {/* </a> */}
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ textAlign: "center", margin: '1rem' }}>The QR Code is sent to your email address.</p>
                                            </div>
                                            <div className="col-12">
                                                <p style={{ textAlign: "center", margin: '1rem' }}>If you didn't receive it in your inbox, please check<br></br> the Spam or Promotions Tab.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            background: 'rgb(24,178,75)',
                                            background: 'linear-gradient(180deg, rgba(24,178,75,1) 0%, rgba(166,211,46,1) 100%)',
                                            padding: '1rem'
                                        }}
                                    >
                                        <div className="row" style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            <div className="col-12">
                                                <p style={{ textAlign: "center", margin: '1rem' }}>Book your calendar now.</p>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                                className="w-100 justify-content-center align-items-center">
                                                {/* <a
                                                    style={{ color: "white", cursor: "pointer" }}
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                    }}
                                                    class="buttonhx ribbon-outset border">Add to Calendar</a> */}
                                                <AddToCalendarModal
                                                    // className={componentStyles}
                                                    buttonText="Custom Button Text"
                                                    // linkProps={{
                                                    //     className: linkStyles,
                                                    // }}
                                                    items={["Google", "iCal"]}

                                                    event={event}
                                                />

                                            </div>
                                            <div className="col-12">
                                                <p style={{ textAlign: "center", margin: '1rem' }}> </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form >

    );
}
