import React from 'react';

export default function StepThree(props) {
  return (
    <div className={props.currentForm === 2 ? 'multisteps-form__panel js-active' : 'multisteps-form__panel'} data-animation="slideHorz">
      <div className="wizard-forms">
        <div className="inner pb-100 clearfix">
          <div className="form-content pera-content" >
            <div className="step-inner-content">
              <span className="step-no bottom-line">Step 3</span>
              <div className="step-progress float-right">
                <span>3 of 4 completed</span>
                <div className="step-progress-bar">
                  <div className="progress">
                    <div className="progress-bar" style={{ width: '60%' }} />
                  </div>
                </div>
              </div>
              <h2>Please review your details</h2>
              <p>Review the details entered before hand. Press the back button if you need to change anything.</p>

              <div className="form-inner-area">
                <div className="row">

                  <div className="col-5">
                    <p style={{ marginRight: 10, fontWeight: 'bold' }}>First Name</p>
                    <p style={{ marginRight: 10, fontWeight: 'bold' }}>Last Name</p>

                    {props.data.middleName !== ''
                                            && <p style={{ marginRight: 10, fontWeight: 'bold' }}>Middle Initial</p>}
                    {props.data.suffix !== ''
                                            && <p style={{ marginRight: 10, fontWeight: 'bold' }}>Suffix</p>}
                    {props.data.proSuffix !== ''
                                            && <p style={{ marginRight: 10, fontWeight: 'bold' }}>Professional Suffix</p>}

                    <p style={{ marginRight: 10, fontWeight: 'bold', whiteSpace: 'nowrap' }}>Certificate Name</p>
                    <p style={{ marginRight: 10, fontWeight: 'bold' }}>Email</p>
                    <p style={{ marginRight: 10, fontWeight: 'bold' }}>Country</p>

                    {props.data.pmaNumber && <p style={{ marginRight: 10, fontWeight: 'bold' }}>PMA Number</p>}
                    {props.data.prcNumber && <p style={{ marginRight: 10, fontWeight: 'bold' }}>PRC Number</p>}
                    {props.data.pdsNumber && <p style={{ marginRight: 10, fontWeight: 'bold' }}>PDS Number</p>}
                    {props.data.fellowship && <p style={{ marginRight: 10, fontWeight: 'bold' }}>Will you attend the Fellowship Night?</p>}
                  </div>
                  <div className="col-7">
                    <p>{props.data.firstName}</p>
                    <p>{props.data.lastName}</p>
                    {props.data.middleName !== ''
                                            && <p>{props.data.middleName}</p>}
                    {props.data.suffix !== ''
                                            && <p>{props.data.suffix}</p>}
                    {props.data.proSuffix !== ''
                                            && <p>{props.data.proSuffix}</p>}
                    <p>{props.data.certName}</p>
                    <p>{props.data.email}</p>
                    <p>{props.data.country}</p>
                    {props.data.pmaNumber && <p>{props.data.pmaNumber}</p>}
                    {props.data.prcNumber && <p>{props.data.prcNumber}</p>}
                    {props.data.pdsNumber && <p>{props.data.pdsNumber}</p>}
                    {props.data.fellowship && <p>{props.data.fellowship ? 'Yes' : 'No'}</p>}
                  </div>
                </div>
              </div>
              <br />

              <div className="session-group">
                <h3>Sessions</h3>
                <div style={{marginLeft: '2rem'}}> 
                  {props.data.sessions.map((session, index) => {
                    return <div key={index} 
                        style={{
                          marginTop: 10
                        }}>
                      {index !== 0 && <hr 
                        style={{
                          width: '40%',
                          borderBottom: '1px solid black',
                          margin: '2rem 0',
                          marginLeft: '-2rem'
                        }}
                      />}
                      <div>
                          <p style={{ marginRight: 10, textTransform: 'uppercase' }}>{session.assignment}</p>
                          <p style={{ marginRight: 10, fontWeight: 'bold' }}>{session.title}</p>
                          <p style={{ marginRight: 10 }}>{session.startTime} - {session.endTime}</p>
                        </div>
                    </div>
                    
                    })}
                </div>
              </div>

              <div className="form-inner-area">
                <div className="image-wrapper">
                  <img style={{width: '100%', maxWidth: '100%'}} src="https://res.cloudinary.com/duuldyiu5/image/upload/v1687770689/PDS/updatedrates_fzceig.png" alt="pricing" />
                </div>
                <p>
                  MODES OF PAYMENT
                  <br />
                  <br />
                  - Credit cards (Visa and MasterCard) will be accepted.
                  <br />
                  - GCash will be available for local delegates only.
                  <br /> <br />
                  *There will be a 2.5-4.5% additional charge on top of the registration rates.
                </p>
                <br />
                <br />
                <p>
                  CANCELLATION POLICY
                  <br />
                  <br />
                  All cancellations must be notified in writing (by email) to the Registration Committee at registration@pdsconvention2023.com.
                  <br />
                  <br />
                  The following rules will apply:
                  <br />
                  <br />
                  - Cancellations received in writing before or by September 15, 2023 are entitled to 100% refund.
                  <br />
                  <br />
                  - Cancellations to be made by October 1 and onwards are no longer entitled to refund.
                  <br />
                  <br />
                  - All refunds will be made within 3 months after the end of the event.
                  <br />
                  <br />
                  If you first registered as an individual and subsequently received sponsorship from industry or company, you will not be entitled to a refund.
                  <br />
                  <br />
                  Applicable bank charges will be deducted accordingly.
                  <br />
                  <br />
                  {' '}
                  <br />
                  SPECIAL REQUESTS
                  <br />
                  <br />
                  Delegates belonging to different registration categories will not be entitled to reimbursement if the fee paid by the old delegate is higher than the new participant fee.
                  <br />
                  <br />
                  By clicking “Next” below, I acknowledge that I have reviewed my registration details and that everything is true and correct to the best of my knowledge.
                  <br />
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="actions">
          <ul>
            {/* <li><span className="js-btn-prev" title="BACK"><i className="fa fa-arrow-left"></i> BACK </span></li> */}
            <li>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  props.handleBack('step_2_5');
                }}
                className="themesflat-button bg-accent js-btn-prev"
              >
                BACK

              </button>
            </li>
            <li>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  props.handleNext();
                }}
                className="themesflat-button bg-accent"
              >
                NEXT

              </button>
            </li>
            {/* <li><span className="js-btn-next" title="NEXT">NEXT <i className="fa fa-arrow-right"></i></span></li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
