/* eslint-disable react/button-has-type */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import env from 'react-dotenv';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'

import { registrationFields } from './constant';
import { city } from './ph';

export default function StepTwo(props) {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [message3] = useState('');
  const [documentURL, setDocumentURL] = useState(null);
  const [setDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);

  const [prcnumber, setPrcNumber] = useState(null);
  const [pdsIdNumber, setPdsIdNumber] = useState(null);
  const [pmaNumber, setPmaNumber] = useState(null);

  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(async () => {
  //     checkEmail(props.email)
  // }, [props.email])
  useEffect(() => {
    // console.log('uefiauaguibawgoipawg', props.selectedOptions)
    setFields(registrationFields[props.selectedOptions.join('_')]);

    const tempFields = registrationFields[props.selectedOptions.join('_')]
    if (tempFields?.includes('philippines')) {
      props.setCountry('Philippines');
    }
  }, [props.selectedOptions]);

  const handleSubmit = async (e) => {

    if (props.firstName === '') {
      NotificationManager.error('Enter your first name.', '', 5000);
      return
    }
    if (props.lastName === '') {
      NotificationManager.error('Enter your last name.', '', 5000);
      return
    }
    if (props.position === '') {
      NotificationManager.error('Enter your position.', '', 5000);
      return
    }
    if (props.email === '') {
      NotificationManager.error('Enter your email.', '', 5000);
      return
    }

    if (props.phone === '') {
      NotificationManager.error('Enter your phone.', '', 5000);
      return
    }
    if (props.brandName === '') {
      NotificationManager.error('Enter your brand name.', '', 5000);
      return
    }
    if (props.company === '') {
      NotificationManager.error('Enter your company.', '', 5000);
      return
    }
    // if (props.branchName === '') {
    //   NotificationManager.error('Enter your branch name.', '', 5000);
    //   return
    // }
    if (props.numberOfBranch === '') {
      NotificationManager.error('Enter number of branch.', '', 5000);
      return
    }
    if (props.city === '') {
      NotificationManager.error('Select your city.', '', 5000);
      return
    }

    if (props.grabAccountManager === '') {
      NotificationManager.error('Enter your grab account manager.', '', 5000);
      return
    }


    // CHECK ERROR MESSAGES
    if (error) {
      NotificationManager.error('Email is not valid. Check email field.', '', 5000);
      return;
    }


    props.setCountry('Philippines');
    props.setLetterUrl(documentURL);

    props.handleNext('step_4');


    // if (props.selectedOptions.includes('speaker')
    //   || props.selectedOptions.includes('board')
    //   || props.selectedOptions.includes('member')
    // ) {
    //   setOpen(true);
    // } else {
    //   props.handleNext('step_2_5');
    // }

  };


  const checkEmail = (e) => {
    if (e !== '') {
      // CREATE SOURCE
      const body = {
        email: e.toLowerCase(),
      };
      axios.post(`${env.API_URL}/metadata/email`, body).then((res) => {
        try {
          if (res.data.error) {
            setMessage(res.data.error);
            setError(true);
            return false;
          }
          setMessage(res.data.message);
          setError(false);
          return true;
        } catch (e) { }
      }).catch((e) => {
        setMessage(e.response.data.error);
        setError(true);
        return false;
      });
    }
  };

  const uploadImage = (image) => {
    const data = new FormData();
    data.append('file', image);
    data.append("upload_preset", "pds_uploads")
    fetch("https://api.cloudinary.com/v1_1/duuldyiu5/image/upload", {
      method: "post",
      body: data
    })
      .then((resp) => resp.json())
      .then((data) => {
        setDocumentURL(data.url);
        setDocumentName(data.original_filename);
        // props.setLetterUrl(data.url);
        // console.log(data.url, data.original_filename)
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    const { value } = e.target;
    console.log(value)
    props.setCity(value)
  };

  return (
    <form id="wizard" onSubmit={handleSubmit}>
      <NotificationContainer />

      <Modal
        open={open}
        onClose={() => { setOpen(false) }}
        center
      >
        <div style={{ margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

          {/* <h2 style={{ textAlign: 'center', lineHeight: 1.5, marginTop: '2rem' }}>Would you like to attend the Fellowship Night ?</h2>
          <div className="actions" style={{ display: "flex", marginTop: '2rem', bottom: 0, right: 0 }}>
            <button
              onClick={(e) => {
                setOpen(false)
                props.setFellowship(false)
                props.handleNext('step_2_5');
              }}
              className="button-next"
              style={{ marginRight: 10, width: '120px', borderRadius: 5 }}
            >
              No
            </button>
            <button
              onClick={(e) => {
                setOpen(false)
                props.setFellowship(true)
                props.handleNext('step_2_5');
              }}
              className="button-next-active"
              style={{ width: '120px', borderRadius: 5 }}
            >
              Yes
            </button>
          </div> */}

          <h2 style={{ textAlign: 'center', lineHeight: 1.5, marginTop: '2rem' }}>Confirm details?</h2>
          <div className="actions" style={{ display: "flex", marginTop: '2rem', bottom: 0, right: 0 }}>
            <button
              onClick={(e) => {
                setOpen(false)
              }}
              className="button-next"
              style={{ marginRight: 10, width: '120px', borderRadius: 5 }}
            >
              Back
            </button>
            <button
              onClick={(e) => {
                setOpen(false)
                props.setFellowship(false)
                props.handleNext('step_2_5');
              }}
              className="button-next-active"
              style={{ width: '120px', borderRadius: 5 }}
            >
              Ok
            </button>
          </div>

        </div>
      </Modal>

      <div style={{
        borderRadius: '2rem',
        background: "rgb(0,173,78)",
        background: "linear-gradient(180deg, rgba(0,173,78,1) 0%, rgba(1,87,58,1) 100%)"
      }} className={props.currentForm === 1 ? 'multisteps-form__panel js-active' : 'multisteps-form__panel'} data-animation="slideHorz">
        {/* div 2 */}
        <div className="wizard-forms">
          <div className="inner clearfix">
            <div className="form-content pera-content">
              <div>
                {/* <span className="step-no bottom-line">Step 2</span>
                <div className="step-progress float-right">
                  <span>2 of 4 completed</span>
                  <div className="step-progress-bar">
                    <div className="progress">
                      <div className="progress-bar" />
                    </div>
                  </div>
                </div> */}
                <div id='headerimage'></div>

                <div className="form-inner-area">
                  <div className="row">
                    <div className=" col-12 col-lg-6 rowright">
                      <p className="sm">First Name</p>
                      <a className={"buttonHex ribbon-outset border"}>
                        <input onChange={(e) => { props.setFirstName(e.target.value); }} value={props.firstName} type="text" name="first_name" id="first_name" className="form-control inputField required" minLength="2" placeholder="First Name *" required autoComplete="off" />
                      </a>
                    </div>
                    <div className=" col-12 col-lg-6 rowleft">
                      <p className="sm">Last Name</p>
                      <a className={"buttonHex ribbon-outset border"}>
                        <input onChange={(e) => { props.setLastName(e.target.value); }} value={props.lastName} type="text" name="last_name" id="last_name" className="form-control inputField required" minLength="2" placeholder="Last Name *" required autoComplete="off" />
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p className="sm">Position / Role</p>
                      <a className={"buttonHex ribbon-outset border"}>
                        <input onChange={(e) => { props.setPosition(e.target.value); }} type="text" name="position" id="position" className="form-control inputField" minLength="2" placeholder="Type here " />
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 col-lg-6 rowright" >
                      <p className="sm">Mobile Number</p>
                      <a className={"buttonHex ribbon-outset border"}>
                        <input onChange={(e) => { props.setPhone(e.target.value); }} value={props.phone} type="tel" id="phone" name="phone" pattern="[+]{1}[0-9]{11,14}" className="form-control inputField required" minLength="2" placeholder="Mobile Number *" required autoComplete="off" />
                      </a>

                    </div>
                    <div className="col-6 col-lg-6 rowleft" >
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <p className="sm">Email Address</p>
                      </div>
                      <a className={"buttonHex ribbon-outset border"}>
                        <input
                          value={props.email}
                          onChange={(e) => {
                            function validEmail(e) {
                              var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
                              return String(e).search(filter) !== -1;
                            }

                            if (validEmail(e.target.value)) {
                              checkEmail(e.target.value)
                            } else {
                              setMessage('Invalid Email')
                              setError(true)
                            }

                            props.setEmail(e.target.value);
                          }}
                          // disabled={props.email}
                          type="email"
                          name="email"
                          id="email"
                          className="form-control required inputField"
                          minLength="5"
                          placeholder="Email *"
                          required
                          autoComplete="off"
                        />
                      </a>
                      <small style={{ color: error && 'red', fontSize: 'small' }}>{message}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 rowright" >
                      <p className="sm">Brand Name</p>
                      <a className={"buttonHex ribbon-outset border"}>
                        <input onChange={(e) => { props.setBrandName(e.target.value); }} value={props.brandName} type="text" name="brandName" id="brandName" className="form-control inputField required" minLength="2" placeholder="Type here *" required autoComplete="off" />
                      </a>
                    </div>
                    <div className="col-12 col-lg-6 rowleft">
                      <p className="sm">Company / Legal Entity Name</p>
                      <a className={"buttonHex ribbon-outset border"}>
                        <input onChange={(e) => { props.setCompany(e.target.value); }} value={props.company} type="text" name="company" id="company" className="form-control inputField required" minLength="2" placeholder="Type here *" required autoComplete="off" />
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-6 col-lg-6" style={{ paddingRight: 5 }}>
                      <p className="sm">Branch Name</p>
                      <a className={"buttonHex ribbon-outset border"}>
                        <input onChange={(e) => { props.setBranchName(e.target.value); }} value={props.branchName} type="text" name="branchName" id="branchName" className="form-control inputField required" minLength="2" placeholder="Type here *" required autoComplete="off" />
                      </a>
                    </div> */}
                    <div className="col-12 col-lg-12">
                      <p className="sm">Number of Branches</p>
                      <a className={"buttonHex ribbon-outset border"}>
                        <input onChange={(e) => { props.setNumberOfBranch(e.target.value); }} value={props.numberOfBranch} type="number" name="numberOfBranch" id="numberOfBranch" className="form-control inputField required" minLength="2" placeholder="Type here *" required autoComplete="off" />
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p className="sm">City</p>
                      <a className={"buttonHex ribbon-outset border"}>
                        <>
                          <select
                            value={props.city} onChange={handleChange}
                            className='inputField selectcity'
                            required>
                            <option value="">Choose here</option>
                            {city.sort(function (a, b) {
                              var textA = a.name.toUpperCase();
                              var textB = b.name.toUpperCase();
                              return textA.localeCompare(textB);
                            }).map((item) => {
                              return <option value={item.name}>{item.name}</option>
                            })}
                          </select>
                        </>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p className="sm">Grab Account Manager</p>
                      <a className={"buttonHex ribbon-outset border"}>
                        <input onChange={(e) => { props.setGrabAccountManager(e.target.value); }} type="text" name="grabAccountManager" id="grabAccountManager" className="form-control inputField" minLength="2" placeholder="Type here " />
                      </a>
                    </div>
                  </div>
                </div>

                <br />
                <p className="sm">By clicking "Next" below, I certify that the above information is true and correct.</p>
                {' '}
                <br />
                <p className="sm">Under RA 10173 of the Republic of the Philippines, all personal information collected, stored and processed by this site shall be duty bound to observe and respect your privacy rights.</p>
              </div>

              <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}>
                <a
                  style={{ color: "white", cursor: "pointer", margin: '40px auto' }}
                  onClick={(e) => {

                    e.preventDefault();
                    handleSubmit()
                    // if (fields[0])
                    // props.setCategory(fields[0])
                    // e.preventDefault();
                    // props.handleNext()
                  }}
                  class="buttonhx ribbon-outset border">Submit</a>


              </div>

            </div>



          </div>

        </div>
      </div>
    </form>
  );
}
